import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import './Training.scss';
import { sanity, imageUrlBuilder } from '../../sanity';
import { useQuery } from 'react-query';


export default function Training(props) {
  let { cover_img, trainer, title, location } = props.data;

  const queryTrainers = ` *[ _type == 'Trainer' ] { name, _id }`;
  const { data: trainers } = useQuery('Trainer', () => sanity.fetch(queryTrainers));
  console.log('trainers', trainers);

  const getName = (id) => {
    let arr = trainers !== undefined?  trainers.filter(t => t._id === id): [];
    console.log(arr);
    return arr.length > 0 ? arr[0].name : '';
  }
  
  return (
    <Card className="m-0 p-0 overflow-hidden card-height" style={{
      backgroundImage: "url(" + `${imageUrlBuilder.image(cover_img).url()}` + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}>
      <Card.Body className="text-center py-5 my-5">
      </Card.Body>
      <Card.Footer className='bg-black bg-opacity-50 text-white border-0 fs-6' >
      <h6 className="">{getName(trainer[0]._ref)}</h6>
      <p className="fw-light"> Teaches : {title}</p>
      <span>Location: {location}</span>
      </Card.Footer>
    </Card>
  );
}