import './App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import Router from './routes';


function App() {

  return (
    <> 
    <Header/>
     <Router/>
     <Footer/>
    </>
  );
}

export default App;
 