import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import trainerImg from '../../assets/trainer.jpg'
import './Home.scss';
import TrainingSlider from '../../components/TrainingSlider';
import Nav from 'react-bootstrap/Nav';
import ReviewSlider from '../../components/ReviewSlider';
import { BASE_URL, page } from '../../constant';
import axios from "axios";
import { useQuery } from 'react-query';
import { sanity, imageUrlBuilder } from '../../sanity';



export default function Home() {
  const [coverImg, setIntroCover] = useState()

  let deviceLink = "";

  if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
    deviceLink = "mailto:info@tatrainers.com?subject=Become A Trainer&body=Hello TA Trainers,%0D%0A%0D%0A"
  } else {
    deviceLink = "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@tatrainers.com&su=Become A Trainer&body=Hello TA Trainers,"
  }

  const explore = (cat) => {
    window.location.href = `${page.Explore}/${cat}`;
  }
  const newSubscriber = (e) => {
    e.preventDefault();
    axios
      .post(`${BASE_URL}/api/subscriptions`, {
        email: e.target[0].value,
      })
      .then((response) => {
        console.log('res', response.data)
        document.getElementById("subscribe").reset();
        document.getElementById("toast").classList.remove("d-none");
        document.getElementById("toast").innerHTML = "Thank you for subscribing to our newsletter"
        setTimeout(() => {
          document.getElementById("toast").innerHTML = ""
          document.getElementById("toast").classList.add("d-none");
        }, 2500)
      });
  };
  const queryCat = ` *[ _type == 'Categories' ] { name, _id }`;
  const { data: categories } = useQuery('Categories', () => sanity.fetch(queryCat));
  console.log('cats', categories);

  useEffect(() => {
    const queryCoverImg = ` *[ _type == 'CoverImage' ]`;
    sanity.fetch(queryCoverImg).then(img => {
      console.error('COVER', img);
      if (img.length > 0) {
        setIntroCover(imageUrlBuilder.image(img[0].cover).url());
      }
    })

  })

  return (
    <section className='bg-primary text-white py-5'>
      <div className='col-lg-12 d-lg-flex p-lg-5 justify-content-between align-items-center pt-5 h-25'>
        <div className="px-lg-5 col-lg-5 mt-5 pt-5">
          <h1 className='big-header'>Find A Trainer and A Mentor </h1>
          <p className="mt-3">THEY RUN THEIR OWN BUSINESSES: And they are ready to help you master the skill and show how to start building your own business.</p>
          <Button variant="danger" type="submit" className='text-white fw-bold my-3 rounded-1' size='lg' onClick={() => explore('all')}>
            FIND YOUR TRAINER
          </Button>
        </div>
        <div className="col-lg-7 d-flex justify-content-center pt-4">
          <img src={coverImg} alt="cover" className='heroCover' />
        </div>
      </div>
      <div className='text-center pt-3'>
        <h1>MEET YOUR TRAINERS</h1>
      </div>
      <div className='xP d-lg-flex justify-content-between align-items-center pt-2'>
        <h3> </h3>
        <Nav.Link onClick={() => explore('all')} className='fw-bold tex-decoration-underline text-danger'>See All</Nav.Link>
      </div>
      <TrainingSlider />
      <div className='text-center py-2'>
        <Button className='text-secondary bg-white col-lg-3' onClick={() => explore('all')}>Explore Trainings</Button>
      </div>
      <div className='vspacer'></div>
      <div className='col-lg-12 d-lg-flex px-lg-5 justify-content-between align-items-center'>
        <div className="px-lg-5 p-4">
          <h1 className='big-header'>ARE YOU A TRAINER?</h1>
          <p className="mt-3">Let us help you find a trainee.</p>
          <Button variant="danger" type="submit" className='text-white col-lg-7 col-12 my-5' >
            <a target="_blank" rel="noreferrer" href={deviceLink} className='no-style fw-bold text-white'>Contact us</a>
          </Button>
        </div>
        <div className="p-4 col-lg-6">
          <img src={trainerImg} alt="cover" className='img-fluid rounded' />
        </div>
      </div>
      <div className='text-center'>
        <h1>REVIEWS</h1>
        <ReviewSlider />
      </div>
      <div className='text-center p-4'>
        <h1>Explore Trainings</h1>
        <div className="d-lg-flex flex-row flex-wrap justify-content-start py-5">
          {categories && categories.map(cat => (
            <div key={cat._id} className='p-2 border border-light rounded shadow text-white w-35 m-2'>
              <h5 onClick={() => explore(cat.name)} className='click'>{cat.name}</h5>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
