import React, { useState } from 'react'
import Logo from '../../assets/logo.png';
import { routePath, page } from '../../constant';
import Search from '../../assets/svg/search.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Header.scss';
import { FaAlignJustify, FaRegTimesCircle } from 'react-icons/fa';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SearchBar from '../SearchBar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useQuery } from 'react-query';
import { sanity } from '../../sanity';



export default function Header() {

  const [show, setShow] = useState(false);
  const [searchResults, setResults] = useState([]);

  const queryCat = ` *[ _type == 'Categories' ] `;
  const { data: categories } = useQuery('Categories', () => sanity.fetch(queryCat));
  console.log('cats', categories);

  const queryTraining = ` *[ _type == 'Trainings' ] `;
  const { data: trainings } = useQuery('Trainings', () => sanity.fetch(queryTraining));
  console.log('trains', trainings);

  const handleCanvas = () => setShow(!show);

  const closeSearchbar = () => {
    let searchbar = document.getElementById('floatingSearchbar');
    searchbar.classList.add('d-none');
  }
  const openSearchbar = () => {
    let searchbar = document.getElementById('floatingSearchbar');
    searchbar.classList.remove('d-none');
  }
  const handleSearch = (e) => {
    console.log(e.target.value);
    if (e.target.value.length === 0) {
      setResults([]);
      return;
    }
    console.log('twapp', trainings);
    let byTitle = trainings.filter(item => item.title.toLowerCase().includes(e.target.value.toLowerCase()));
    setResults(byTitle);
    console.log(searchResults);
  }
  const viewTraining = (tr) => {
    window.location.href = `${page.Training}/${tr._id}`;
  }

  let deviceLink = "";
  if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
    deviceLink = "mailto:info@tatrainers.com?subject=Become A Trainer&body=Hello TA Trainers,%0D%0A%0D%0A"
  } else {
    deviceLink = "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@tatrainers.com&su=Become A Trainer&body=Hello TA Trainers,"
  }


  return (
    <header className="col-12 xP bg-black fixed-top">
      <nav className='desktop d-none d-lg-flex flex-row align-items-center justify-content-start py-1'>
        <Nav.Link href={routePath.Home} className='col-2 display-6 text-white'>
           <h2> TA Trainers </h2>
          {/* <img src={Logo} alt="Trainers" className='col-10 object-fit-contain' /> */}
        </Nav.Link>
        <div className='d-flex align-items-center' style={{ marginLeft: 'auto' }}>
          <DropdownButton
            align="end"
            title="All Categories"
            id="dropdown-menu-align-end"
            className='px-2'
          >
            {categories && categories.map(cat => (
              <Dropdown.Item className='col-lg-5 text-black' key={cat._id} href={`${page.Explore}/${cat.name}`}>{cat.name}</Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.Item eventKey="btn" href={`${page.Explore}/all`} className="text-danger">Discover More</Dropdown.Item>
          </DropdownButton>

          <Nav.Link className="searchbar text-white mx-2" onClick={() => openSearchbar()}>
            <img src={Search} alt="search" className='icon mx-2' />
            Search
          </Nav.Link>
        </div>
        <div className='d-flex align-items-center' style={{ marginLeft: 'auto' }}>
          <span className="small text-white">Contact us 0726175021</span>
          <Button variant='outline-danger' className='mx-3'>
            <a target="_blank" rel="noreferrer" href={deviceLink} className="text-decoration-none text-light">Become a Trainer</a>
          </Button>
        </div>
      </nav>
      <div id="floatingSearchbar" className="d-none col-lg-4 floating-searchbar rounded text-white">
        <div className="d-flex justify-content-end">
          <Button variant='outline-danger' onClick={() => closeSearchbar()}>Close</Button>
        </div>
        <Form >
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Search for a Training</Form.Label>
            <Form.Control type="text" placeholder="Search" onChange={handleSearch} />
          </Form.Group>
        </Form>
        {searchResults.map((item, index) => (
          <div key={index} className="p-2 bg-white text-primary fw-bold click rounded" onClick={() => viewTraining(item)}>
            <h6>{item.title}</h6>
            <p className="text-danger">Location: {item.location}</p>
          </div>
        ))}
      </div>
      <div className='mobile d-lg-none text-white d-flex flex-row justify-content-between align-items-center'>
        <Nav.Link href={routePath.Home} className="col-5"><img src={Logo} alt="Trainers" className='col-12 object-fit-contain' /></Nav.Link>
        <div>
          <DropdownButton
            align="end"
            title="All Categories"
            id="dropdown-menu-align-end"
          >
            {categories && categories.map(cat => (
              <Dropdown.Item className='col-lg-5 text-black' key={cat._id} href={`${page.Explore}/${cat.name}`}>{cat.name}</Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.Item eventKey="btn" href={`${page.Explore}/all`} className="text-danger">Discover More</Dropdown.Item>
          </DropdownButton>
        </div>
        <div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className='text-white menuIcon' onClick={handleCanvas}>
            <FaAlignJustify />
          </Navbar.Toggle>
        </div>
        <Offcanvas show={show} onHide={handleCanvas} className="text-primary">
          <Offcanvas.Header className="text-white bg-primary">
            <Offcanvas.Title><h1>Trainers</h1></Offcanvas.Title>
            <FaRegTimesCircle closeButton className='fs-1 btn-close text-reset' onClick={handleCanvas} />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="col-lg-12">
              <SearchBar />
              <h5 className='mt-4 fw-bold fs-4'>Check out our categories</h5>
              <DropdownButton
                align="end"
                title="All Categories"
                id="dropdown-menu-align-end"
                variant="outline-secondary"
                className="my-3 col-lg-12"
              >
                {categories && categories.map(cat => (
                  <Dropdown.Item className='col-lg-5 text-black' key={cat._id} href={`${page.Explore}/${cat.name}`}>{cat.name}</Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item eventKey="btn" href={`${page.Explore}/all`} className="text-danger">Discover More</Dropdown.Item>
              </DropdownButton>
              <div className='my-5'>
                <Nav.Link className="mt-3 fs-4 fw-bold text-decoration-underline">Explore</Nav.Link>
                <a target="_blank" rel="noreferrer" href={deviceLink} className='no-style fw-bold text-white'>Become a Trainer</a>
              </div>
              <div className='mt-5 py-5'>
                <h4>Learn something new every month.</h4>
                <p>Suscribe to our newsletter.</p>
                <Form className='col-lg-12'>
                  <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email address" />
                  </Form.Group>
                  <Button variant='danger' className='col-lg-12 text-white fw-bold' size="lg">SUBSCRIBE</Button>
                </Form>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </header>
  )
}
