import React, { useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "./Review.scss";
import { useQuery } from 'react-query';
import { sanity } from '../../sanity';

export default function ReviewSlider() {

  const queryCat = ` *[ _type == 'Testimonies' ] { message, from }`;
  const { data: testimonies } = useQuery('Testimonies', () => sanity.fetch(queryCat));
  console.log('testimonies', testimonies);

  
  if (testimonies != undefined && testimonies.length > 0) {

    return (
      <section className={["bg-light", "pb-2"].join(" ")}>
        {/* <h1 className="text-primary">What our clients are saying…</h1> */}
        <Carousel fade>
          {testimonies.map((item, index) => (
            <Carousel.Item key={index} interval={2000}>
              <div className="review bg-white text-black ">
                <p className="my-2"> {item.message}</p>
                <div className={["d-flex", "justify-content-end", "align-items-center", "my-3"].join(" ")}>
                  <p style={{textTransform: 'capitalize'}}> -- &nbsp;{item.from} </p>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </section>
    );
  } else {
    return (null)
  }

}
