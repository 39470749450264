import React, { useEffect, useState } from "react";
import ReviewSlider from "../../components/ReviewSlider";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "./Training.scss";
import { page } from "../../constant";
import Gallery from "../../components/Gallery";
import { useQuery } from 'react-query';
import { sanity, imageUrlBuilder } from '../../sanity';


export default function Training() {
  const [activeTab, setActiveTab] = useState("info");

  let { id } = useParams();

  const queryTraining = ` *[_id == '${id}' ][${0}]`;
  const { data: trs } = useQuery('Trainings', () => sanity.fetch(queryTraining));
  let training;
  if (trs) {
    training = trs.filter(train => train._id === id)[0];
    // console.log('training-->|', training);
  }


  const queryTrainer = ` *[ _type == 'Trainer' ] `;
  const { data: trainers } = useQuery('Trainer', () => sanity.fetch(queryTrainer));
  // console.log('trainers -->|', trainers);


  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  let trainer;

  const getName = (id) => {
    console.log('id', id)
    let arr = trainers !== undefined ? trainers.filter(t => t._id === id) : [];
    trainer = arr[0];
    return arr.length > 0 ? arr[0].name : '';
  }

  const enroll = (name) => {
    window.location.href = `${page.Enroll}/${name}/${training.trainer[0]._ref}`;
  }

  useEffect(() => {

  })


  return (
    <section className="bg-primary text-white m-0">
      {training !== undefined && (
        <div
          className="t-height text-center pt-5 w-full"
          style={{
            backgroundImage: "url(" + `${imageUrlBuilder.image(training.cover_img).url()}` + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="col-12 bg-black py-3 bg-opacity-50 ">
            <h1>{training.title}</h1>
            <h3 className="">By: {training.trainer.map(tr => (<span key={tr._ref}>{getName(tr._ref)},</span>))}</h3>
            <Button variant="danger" className="px-5 col-lg-3 text-white mt-4" onClick={() => enroll(training.title)}>
              ENROLL
            </Button>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center col-lg-12 my-5 xP px-2">
        <div
          className={`mx-5 click ${activeTab === "info" ? "active-tab" : ""}`}
          onClick={() => changeTab("info")}
        >
          <h6>Training Info</h6>
        </div>
        <div
          className={`mx-5 click ${activeTab === "trainer" ? "active-tab" : ""
            }`}
          onClick={() => changeTab("trainer")}
        >
          <h6>Trainer</h6>
        </div>
        <div
          className={`mx-5 click ${activeTab === "gallery" ? "active-tab" : ""
            }`}
          onClick={() => changeTab("gallery")}
        >
          <h6>Gallery</h6>
        </div>
      </div>

      {
        activeTab === 'info' ?
          <div className="xP my-5">
            <h5>About this Training :</h5>
            <p className="my-5">
              {training !== undefined && training.description}
            </p>
            <h5>Location : {training !== undefined && training.location}</h5>
            <h5>Fee : Ksh {training !== undefined && training.fee}</h5>
          </div>
          : activeTab === 'trainer' ?
            <div className="xP my-5 d-flex align-items-center py-5">
              <img className=" img-fluid profile" src={imageUrlBuilder.image(trainer.profile_img).url()} alt="trainer" />
              <div className="mx-5">
                <h4>Trainer: {trainer.name}</h4>
                <p>{trainer.bio}</p>
              </div>
            </div>
            :
            <div className="xP my-2">
              <Gallery images={training !== undefined && training.gallery} />
            </div>
      }
      <div className='my-2 col-12 text-center'>
        <Button variant="danger" className="px-5 col-lg-6 text-white mt-5" onClick={() => enroll(training.title)}>
          ENROLL
        </Button>
      </div>
      <div className="vspacer"></div>
      <div className="text-center xP">
        <ReviewSlider />
      </div>
    </section>
  );
}
