import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import { FaTwitter, FaFacebook, FaYoutube } from 'react-icons/fa';
import './Footer.scss';
import Logo from '../../assets/logo.png';
import { sanity } from '../../sanity';


export default function Footer() {
  const [socials, setSocial] = useState({facebook:"",twitter:"",youtube:""});
  const newSubscriber = (e) => {
    e.preventDefault();
    let msg = {
      _type: 'Subscriptions',
      email: e.target[0].value,
    }
    sanity.create(msg).then((data) => {
      console.log('data ==>', data);
      document.getElementById("s_form").reset();
        document.getElementById("msg").classList.remove("d-none");
        document.getElementById("msg").innerHTML = "Thank you for subscribing to our newsletter"
        setTimeout(() => {
          document.getElementById("msg").innerHTML = ""
          document.getElementById("msg").classList.add("d-none");
        },2500)
    })
  };

  useEffect(() => {
    const socials = ` *[ _type == 'SocialLinks' ]`;
    sanity.fetch(socials).then((data) => {
      console.error('data ==>', data);
      if(data.length > 0) {
        setSocial(data[0])
      }
    });
  },[]);

  return (
    <footer className='bg-black text-white xP'>
      <div className='d-lg-flex justify-content-between align-items-top py-5'>
        <div>
          <h5>Follow us</h5>
          <Nav defaultActiveKey="/home" className="flex-column py-3">
            <Nav.Link href={socials.twitter} target="_blank" className="text-white px-0 py-2 small"><FaTwitter className="twitterIcon" />&nbsp;Twitter</Nav.Link>
            <Nav.Link href={socials.facebook} target="_blank" className="text-white px-0 py-2 small"><FaFacebook className="facebookIcon" /> &nbsp;Facebook</Nav.Link>
            <Nav.Link href={socials.youtube} target="_blank" className="text-white px-0 py-2 small"><FaYoutube className="youtubeIcon" /> &nbsp;Youtube</Nav.Link>
          </Nav>
          <div className="logo mt-4">
            <img src={Logo} alt="" />&nbsp;&nbsp;
            <span>© 2022 Trainers</span>
          </div>
        </div>
        <div className='mb-3'>
          <h5 className='mb-5'>Stay up to date with our Trainings.</h5>
          <p>Enter your email and we’ll send you some samples of our favorite trainings.</p>
        </div>
        <Form id="s_form" className='col-lg-4' onSubmit={newSubscriber}>
          <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control name="email" type="email" placeholder="Enter your email address" required />
          </Form.Group>
          <Button type="submit" variant='outline-danger' className='col-lg-6'>SUBSCRIBE</Button>
        <div style={{background:'green'}} className="d-none my-2 w-75 p-1 rounded text-center fw-light text-white" id="msg"></div>
        </Form>
      </div>
    </footer>
  )
}
