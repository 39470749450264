import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Training from './Training';
import { page } from '../../constant';
import { useQuery } from 'react-query';
import { sanity } from '../../sanity';

export default function TrainingSlider() {


  
  const queryTraining = ` *[ _type == 'Trainings' ] `;
  const { data: training } = useQuery('Trainings', () => sanity.fetch(queryTraining));
  console.log('trainings', training);
  
  const viewTraining = (tr) => {
    console.log(tr);
    window.location.href = `${page.Training}/${tr._id}`;
  }

 
  return (
    <div className="container pb-4 justify-content-center bg-dark">
      <Swiper
        freeMode={true}
        grabCursor={true}
        modules={[FreeMode, Autoplay, Pagination, Navigation]}
        className={"mySwiper"}
        slidesPerView={5}
        pagination={{ clickable: true }}
        spaceBetween={30}
        autoplay={{ delay: 3000 }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 15
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 30
          },
        }}
      >
        {training && training.map(tr => (
          <SwiperSlide key={tr._id} className="py-5" onClick={() => viewTraining(tr)}>
            <Training data={tr} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}