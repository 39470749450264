import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Enroll from "../pages/Enroll";
import Explore from "../pages/Explore";
import Home from "../pages/Home";
import Training from "../pages/Training";
import { routePath } from "../constant";



export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routePath.Home} element={<Home />} />
        <Route path={routePath.Explore} element={<Explore />} />
        <Route path={routePath.Training} element={<Training />} />
        <Route path={routePath.Enroll} element={<Enroll />} />
        <Route path="*" element={<Navigate to={routePath.Home} replace />} />
      </Routes>
    </BrowserRouter>
  );
}
