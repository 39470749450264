import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import ReviewSlider from '../../components/ReviewSlider';
import Nav from 'react-bootstrap/Nav';
import './Explore.scss';
import Training from '../../components/TrainingSlider/Training';
import { page } from '../../constant/index';
import { useQuery } from 'react-query';
import { sanity } from '../../sanity';


export default function Explore() {

    let { name } = useParams();

    const [selectedCategory, setSelectedCat] = useState({ _id: 0, name: name });
    const [trainings, setTrainings] = useState([])
    const [category, setCategory] = useState([])
    const [temp, setTemp] = useState([])

    // const queryCat = ` *[ _type == 'Categories' ] `;
    // const { data: category } = useQuery('Categories', () => sanity.fetch(queryCat));


    // const queryTraining = ` *[ _type == 'Trainings' ] `;
    // let { data: temp } = useQuery('Trainings', () => sanity.fetch(queryTraining));
    // let bp = [];
    // if (temp !== undefined && trainings.length === 0) {
    //     setTrainings(temp)
    // }

    const viewTraining = (tr) => {
        console.log(tr);
        window.location.href = `${page.Training}/${tr._id}`;
    }

    const changeCategory = (cat) => {
        console.warn('temp', temp);
        console.warn('trainings', trainings)
        let trs = []
        if(cat.name === 'all') return setTrainings(temp);
        temp.forEach((item, index) => {
            let fil = item.category.filter(ct => ct._ref === cat._id);
            if (fil.length > 0) {
                trs.push(item);
            }
            if (index === temp.length - 1) {
                setTrainings(trs);
                setSelectedCat(cat);
                if (window.innerWidth < 960){
                    let viewEl = document.getElementById("tr");
                    viewEl.scrollIntoView({block: "start",behavior: "smooth"});
                }
            }
        })
        console.error('trainings', trainings)
    }

    useEffect(() => {
        const queryCat = ` *[ _type == 'Categories' ] `;
        const queryTraining = ` *[ _type == 'Trainings' ]`;
        sanity.fetch(queryTraining).then(trs => {
            console.warn('trainings', trs);
            setTemp(trs)
            sanity.fetch(queryCat).then(data => {
                setCategory(data);
                let selected = data.filter(ct => ct.name === name);
                console.warn('category', selected);
                console.warn('temp', temp);
                
                let newArr = [];
                trs.forEach((item,index) => {
                    let fil = item.category.filter(ct => ct._ref === selected[0]._id);
                    if (fil.length > 0) {
                        newArr.push(item);
                    }
                    if(index === trs.length-1 && newArr.length>0){
                        setTrainings(newArr);
                    }
                })
                console.error('FILTER', newArr);
            });
        });
    }, [name])



    if (trainings !== undefined && category !== undefined) {
        return (
            <section className='bg-primary text-white' style={{ paddingTop: '7%' }}>
                <div className='d-lg-flex xP mb-5'>
                    <div className='col-lg-3'>
                        <Nav defaultActiveKey="/home" className="flex-column py-3">
                            <Nav.Link className={`text-white px-0 py-2 small ${selectedCategory.name === 'all' ? 'active' : ''}`} onClick={() => changeCategory({ _id: '0', name: 'all' })}>All Trainings</Nav.Link>
                        </Nav>
                        <Nav defaultActiveKey="/home" className="flex-column pb-2">
                            {category.map(cat => (
                                <Nav.Link key={cat._id} className={`text-white px-0 py-2 small ${selectedCategory.name === cat.name ? 'active' : ''}`} onClick={() => changeCategory(cat)}>{cat.name}</Nav.Link>
                            ))}
                        </Nav>
                    </div>
                    {trainings.length > 0 ? <div className='col-lg-7 d-lg-flex flex-wrap justify-content-start' id="tr">
                        {trainings.map(tr => (
                            <div className='w-35 m-2 click' key={tr._id} onClick={() => viewTraining(tr)}>
                                <Training data={tr} />
                            </div>
                        ))}
                    </div> : null}
                </div>
                <div className='vspacer'></div>
                <div className='text-center xP'>
                    <h1>See what our members are saying.</h1>
                    <ReviewSlider />
                </div>
            </section>
        )
    }
    else return (<div>Loading ...</div>)
}
