import React, { useState } from 'react'
import './Search.scss';
import { FaSearch } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { sanity } from '../../sanity';
import { page } from '../../constant';
import Moment from 'react-moment';



export default function SearchBar() {
    const [show, setShow] = useState(false);
    const [searchResults, setResults] = useState([]);

    const queryTraining = ` *[ _type == 'Trainings' ] `;
    const { data: trainings } = useQuery('Trainings', () => sanity.fetch(queryTraining));
    console.log('trains', trainings);

    const handleSearch = (e) => {
        console.log(e.target.value);
        if (e.target.value.length === 0) {
            setResults([]);
            return;
        }
        console.log('tr', trainings);
        let byTitle = trainings.filter(item => item.title.toLowerCase().includes(e.target.value.toLowerCase()));
        setResults(byTitle);
        console.log(searchResults);
    }
    const viewTraining = (tr) => {
        window.location.href = `${page.Training}/${tr._id}`;
    }
    return (
        <>
        <div className="bg-white col-lg-12 search d-lg-flex align-item-center justify-content-between">
            <input type="text" placeholder="Search" className="bg-white border-0 text-primary col-9s" onChange={handleSearch} />
            <FaSearch />
        </div>
        {searchResults.map((item, index) => (
                <div key={index} className="p-2 bg-white text-primary fw-bold click rounded" onClick={() => viewTraining(item)}>
                    <h6>{item.title}</h6>
                    <p className="text-danger">Location: {item.location}</p>
                </div>
            ))}
        </>
        
    )
}
