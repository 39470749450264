
import SanityClient from '@sanity/client';
import ImageUrlBuilder from '@sanity/image-url';

export const sanity = SanityClient({
  projectId: 'ghyg6a16',
  dataset: 'production',
  // this option enables faster responses
  // but can return stale data at times.
  // recommended for client-side queries
  useCdn: true,
  token:
    'skhPt86nTQwwvBNUTH2Qdegj35Zz9e0OMnPKXXzUYm1hUYUpuUlZs8lagEu4S4armn5H15xlkfewo6wPUfpkOW1pRRrPZzArAiQrRREbmlUi2nH1U22pVEKF6ywTFxLAJUo8j4cobObkmuioAlG4UpI73lip0LJVD4fFZfHvc5uVoFhsTs8q',
});

export const imageUrlBuilder = ImageUrlBuilder(sanity);
