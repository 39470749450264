export const routePath = {
    Home: "/",
    Explore: "/explore/:name",
    Training: "/training/:id",
    Enroll: "/enroll/:name/:tutor"
}

export const page = {
    Explore: "/explore",
    Training: "/training",
    Enroll: "/enroll"
}

export const BASE_URL = "https://trainer-vhwm.onrender.com" // "http://localhost:3000"
