import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './Enroll.scss';
import { useParams } from "react-router-dom";
import { sanity } from '../../sanity';


export default function Enroll() {
  const { name, tutor } = useParams();

  const HandleSubmit = (e) => {
    e.preventDefault();
    let msg = {
      date_submitted: new Date(),
      name: e.target[0].value,
      phone: e.target[1].value,
      email: e.target[2].value,
      training: name,
      tutor: {
        _ref: tutor,
        _type: 'reference'
      },
      _type: 'Enrollments'
    };
    const queryCat = ` *[ _type == 'Enrollments' &&  email == '${e.target[2].value}' && training == '${name}'] && tutor == '${tutor}' `;
    sanity.fetch(queryCat).then(res => {
      console.log(res);
      if (res) {
        document.getElementById("enrolled").classList.remove("d-none");
        document.getElementById("enrolled").innerHTML = "You have already enrolled to this course."
        document.getElementById("enrolled").classList.add("d-none w-75 p-3 text-bg-success rounded text-center fw-light");
        setTimeout(() => {
          document.getElementById("enrolled").innerHTML = ""
          document.getElementById("enrolled").classList.add("d-none");
        }, 6500)
        return;
      } else {
        console.log('ENROLLMENT', msg)
        sanity.create(msg).then((data) => {
          console.log('data ==>', data);
          document.getElementById("enroll_form").reset();
          document.getElementById("enrolled").classList.remove("d-none");
          document.getElementById("enrolled").innerHTML = "Thank you for Enrolling to this course, our team will reach out to you."
          document.getElementById("enrolled").classList.add("d-none w-75 p-3 text-bg-success rounded text-center fw-light");
          setTimeout(() => {
            document.getElementById("enrolled").innerHTML = ""
            document.getElementById("enrolled").classList.add("d-none");
          }, 6500)
        })
      }
    })
  }


  return (
    <>
      <div className='xP bg-primary text-white d-flex justify-content-center py-5 mt-5'>
        <Form className='col-lg-6 my-5' id="enroll_form" onSubmit={HandleSubmit}>
          <div className='text-center pb-4 mb-4'>
            <h1>Your Trainer is Waiting.</h1>
            <h6>Contact us <b className="text-danger text-decoration-underline mx-2">0726175021</b></h6>
          </div>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter your Name" name="name" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="phone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control type="phone" placeholder="Enter phone number" name="phone" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" name="email" />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Button type="submit" variant="danger" className="px-5 col-lg-12 text-white mt-5">
            ENROLL
          </Button>
        </Form>
      </div>
      <div style={{ background: 'green' }} className="d-none w-full p-3 text-bg-success rounded text-center fw-light" id="enrolled"></div>
    </>
  )
}
