import React from 'react'
import { Carousel } from "react-bootstrap";
import "./Gallery.scss";
import { imageUrlBuilder } from '../../sanity';

export default function Gallery({images}) {

  return (
    <section className="bg-light pb-5 gp my-5 py-5">
    <Carousel fade>
      {images.map((item, index) => (
        <Carousel.Item key={index} interval={2000} className="text-center rounded">
          <img className="img-fluid" src={imageUrlBuilder.height(350).image(item).url()} alt="img"/>
        </Carousel.Item>
      ))}
    </Carousel>
    </section>
  )
}
